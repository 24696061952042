html {
  height: 100%;
  font-size: 14px;
  font-family: "Noto Sans TC", sans-serif;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  color: var(--text-color);
}

.white-space-prewrap {
  white-space: pre-wrap;
}

.pb-100 {
  padding-bottom: 100%;
}

.-m-t-1 {
  margin-top: -1px;
}

.w-0 {
  width: 0;
}

.shadow {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

::placeholder {
  color: #bbbbbb;
}
