.login-wrap {
  // background: linear-gradient(to top right, #354a67, #407a64);
  background-image: url("/images/bg_login.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .login-inner-wrap {
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px #d3d3d3;
  }
}
