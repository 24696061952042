.field {
  margin-bottom: 1.5rem;

  small {
    &.p-error {
      margin-left: 0.7rem;
    }
  }
}

.p-sidebar-left {
  .p-sidebar {
    top: -1.75rem;
    min-width: 130px;
    width: 130px !important;
    height: calc(100% - 3.5rem);
    border-width: 1px 1px 0 0;
    // transform: none !important;

    .p-sidebar-header {
      display: none;

      & + .p-sidebar-content {
        padding: 0 0 0.25rem;
      }
    }
  }
}

.p-sidebar-mask {
  top: 3.5rem;
}

.p-menu {
  padding-top: 0 !important;

  .p-menu-list {
    .p-submenu-header {
      font-size: 1.2rem;
    }

    .p-menuitem-link {
      justify-content: end;
      border-right: 2px solid transparent;

      &:focus {
        box-shadow: none;
      }
    }

    .p-menuitem {
      text-indent: 1rem;

      &.menu-active {
        .p-menuitem-link {
          border-right: 2px solid #07d3af;

          .p-menuitem-text {
            font-weight: bold;
            color: #6296e9;
          }
        }
      }
    }
  }
}

.p-toast-message {
  .p-toast-message-content {
    align-items: center;

    .p-toast-message-text {
      .p-toast-summary,
      .p-toast-detail {
        white-space: pre-wrap;
      }
    }
  }
}

.p-breadcrumb {
  padding: 0.5rem 2px 3px !important;
  background: transparent !important;

  ul {
    li {
      .p-menuitem-link {
        padding: 2px;
        border-radius: 2px;
      }
    }
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    background: transparent;

    .p-tabmenuitem {
      .p-menuitem-link {
        background: transparent;
      }

      &:not(.p-highlight):not(.p-disabled):hover,
      &.p-highlight {
        .p-menuitem-link {
          background: transparent;
        }
      }
    }
  }
}

.p-datatable {
  .p-datatable-table {
    width: 100%;

    .p-datatable-thead {
      tr {
        th {
          padding: 0.7rem;
          background: #fafafa;
        }
      }
    }

    .p-datatable-tbody {
      tr {
        // border-bottom: 1px solid #dee2e6;

        td {
          align-items: flex-start !important;
          padding: 0.7rem;
          word-break: break-word;

          .p-column-title {
            min-width: 15%;
          }
        }

        &:nth-of-type(even) {
          td {
            background-color: #f9f9f9;
          }
        }

        &.p-datatable-emptymessage {
          td {
            width: 100%;
            color: #bb0000;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .p-datatable {
    &:not(.p-datatable-scrollable) {
      .p-datatable-tbody {
        & > tr {
          border-bottom: 1px solid #dee2e6;

          & > td {
            border: none;
          }
        }
      }
    }
  }
}

.p-dropdown {
  .p-dropdown-label {
    &.p-placeholder {
      color: #bbbbbb;
    }
  }
}

.p-disabled {
  background-color: #eeeeee;
}

.p-fluid {
  .p-dropdown {
    width: 100%;
  }
}

.p-button,
.p-inputtext,
.p-dropdown {
  border-radius: 0;
}

.p-inputnumber-input,
.p-password-input {
  width: 100%;
}

.rotate-icon {
  .p-button-icon {
    transform: rotate(-90deg);
  }
}
